import {
  useState,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  gutterBottom?: boolean;
  size?: "medium" | "small";
  sx?: SxProps,
  onBlur?: () => void;
  onChange?: (search: string) => void;
  onFocus?: () => void;
  onSearch?: (search: string) => void;
}

export default function SearchField({
  gutterBottom,
  size,
  sx,
  onBlur,
  onChange,
  onFocus,
  onSearch,
}: Props) {
  const [text, setText] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    onChange?.(event.target.value);
  };

  const handleClear = () => {
    setText('');
    onChange?.('');
  };

  const handleSearch = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && text) {
      onSearch?.(text);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom={gutterBottom ? 0 : 3}
      position="relative"
      sx={sx}
    >
      <TextField
        fullWidth
        label="Search"
        type="search"
        variant="outlined"
        size={size}
        value={text}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyUp={handleSearch}
      />
      <IconButton
        size="small"
        disabled={!text}
        onClick={handleClear}
        sx={{
          position: 'absolute',
          right: 4,
        }}
      >
        {text ? <ClearIcon /> : <SearchIcon />}
      </IconButton>
    </Box>
  );
}