import {
  useState,
} from 'react';
import useTheme from '@mui/material/styles/useTheme';
import AppBar from '@mui/material/AppBar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewGalleryIcon from '@mui/icons-material/ViewModule';
import SearchField from '../SearchField';
import SearchToggleButton from '../SearchToggleButton';
import {
  ActionBarProps,
} from './index.common';

export default function MobileActionBar({
  count,
  onAdd,
  onClear,
  onFilter,
  onRemove,
  onSelect,
}: ActionBarProps) {
  const [filterFocused, setFilterFocused] = useState<boolean>(false);
  const theme = useTheme();

  const addButton = (
    <Tooltip title="Add a card">
      <IconButton onClick={onAdd} sx={{ marginLeft: 1 }}><AddIcon /></IconButton>
    </Tooltip>
  );
  const removeButton = (
    <Tooltip title="Remove selected cards">
      <IconButton onClick={onRemove} sx={{ marginLeft: 1 }}><RemoveIcon /></IconButton>
    </Tooltip>
  );
  const selectionCheckbox = (
    <Checkbox checked={count > 0} onChange={(_, value) => value ? onSelect?.() : onClear?.()} sx={{ marginLeft: 1 }}/>
  );

  return (
    <>
      {filterFocused &&
        <div style={{
          backgroundColor: '#00000080',
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: theme.zIndex.appBar - 1,
        }} />
      }
      <AppBar
        color="inherit"
        position="sticky"
        sx={{
          top: 0,
          bottom: 'auto',
        }}
      >
        <Toolbar sx={{ paddingX: 1 }}>
          {filterFocused || <>
            <SearchToggleButton
              name="view"
              size="small"
              left={{
                label: 'list',
                element: <ViewListIcon />,
              }}
              right={{
                label: 'gallery',
                element: <ViewGalleryIcon />,
              }}
            />
          </>}
          <SearchField
            gutterBottom
            size="small"
            sx={{ flex: 1, marginLeft: 1 }}
            onBlur={() => setFilterFocused(false)}
            onChange={onFilter}
            onFocus={() => setFilterFocused(true)}
          />
          {filterFocused || <>
            {selectionCheckbox}
            {count > 0 ? <>
              {removeButton}
            </> : <>
              {addButton}
            </>}
          </>}
        </Toolbar>
      </AppBar>
    </>
  );
}
