import {
  ReactElement,
} from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ProfileIcon from '@mui/icons-material/AccountCircleOutlined';
import InventoryIcon from '@mui/icons-material/Inventory2Outlined';
import WishlistIcon from '@mui/icons-material/LoyaltyOutlined';

export const DRAWER_WIDTH = 250;

export interface DrawerItemProps {
  label: string;
  link?: string;
  depth?: number;
  icon?: ReactElement;
}
export function DrawerItem ({ label, link, depth, icon }: DrawerItemProps) {
  return link ? (
    <Link to={link}>
      <ListItemButton sx={{ pl: (depth ?? 0) * 2 + 2}}>
        <ListItemIcon >{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </Link>
  ) : (
    <ListItem sx={{ pl: (depth ?? 0) * 2 + 2}}>
      <ListItemIcon >{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
}

export function DrawerMenu () {
  return (
    <List component="nav">
      <DrawerItem label="My Lists" icon={<ProfileIcon />} />
      <List component="div" disablePadding>
        <DrawerItem label="Inventory" link="/profile/inventory" depth={1} icon={<InventoryIcon />} />
        <DrawerItem label="Wishlist" link="/profile/wishlist" depth={1} icon={<WishlistIcon />} />
      </List>
    </List>
  );
}

export interface SideDrawerProps {
  open?: boolean;
  onClose?: () => void;
}
