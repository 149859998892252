import scryfall, {
  Card as ScryfallCard,
} from './scryfall';

export const BORDER_COLOR = '#151515';
export const FOIL_COLOR = 'linear-gradient(45deg, rgba(0,108,255,0.3) 0%, rgba(188,255,59,0.4) 55%, rgba(255,132,0,0.3) 100%)';
export const ART_ASPECT_RATIO = '12 / 17';

export type Foiling = '' | 'nonfoil' | 'foil' | 'etched';

export interface CardEntry {
  /** ID in the app database for this entry. */
  id: string;

  /** Oracle name of the card. */
  name: string;

  /** Number of cards for this entry. */
  quantity: number;

  /** Whether the entry is nonfoil or foil. A value of undefined means either is acceptible. */
  foil?: Foiling;
  
  /**
   * ID for the specific printing of this entry, if there is one.
   * If there's not, then everything will be queried off the card name
   * which is luckily unique for game purposes.
   */
  printing?: string;
}

export class CardObject {
  private _loading: boolean;
  private _scryfall: Promise<ScryfallCard>;
  private _printing?: string;

  public id: string;
  public name: string;
  public quantity: number;

  public foil?: Foiling;
  
  public get printing() : string | undefined {
    return this._printing;
  }

  constructor (entry: CardEntry) {
    this.id = entry.id;
    this.name = entry.name;
    this.quantity = entry.quantity;
    this.foil = entry.foil;
    this._printing = entry.printing;

    this._loading = true;
    this._scryfall = this._printing ? scryfall.getCardById(this._printing) : scryfall.getCardByName(this.name);

    this._scryfall.then(() => this._loading = false);
  }

  public getScryfallCard(): Promise<ScryfallCard> {
    return this._scryfall;
  }

  public isLoading(): boolean {
    return this._loading;
  }

  public setPrinting(printing: string | ScryfallCard) {
    if (typeof printing === 'object') {
      this._scryfall = Promise.resolve(printing);
    } else {
      this._loading = true;
      this._scryfall = scryfall.getCardById(printing);
    }
    this._scryfall.then(card => {
      this._loading = false;
      this._printing = card.id;
    });
  }

  public toEntry(): CardEntry {
    return {
      id: this.id,
      name: this.name,
      quantity: this.quantity,
      foil: this.foil,
      printing: this._printing,
    };
  }

  public update(entry: CardEntry): void {
    const reload = this.name !== entry.name || this._printing !== entry.printing;

    this.quantity = entry.quantity;
    this.foil = entry.foil;
    
    if (reload) {
      this.name = entry.name;
      this._printing = entry.printing;

      this._loading = true;
      this._scryfall = this._printing ? scryfall.getCardById(this._printing) : scryfall.getCardByName(this.name);
  
      this._scryfall.then(() => this._loading = false);
    }
  }
}

/**
Data Model Notes
Things That Users Can Specify:
  - Name
  - Foiling
  - Printing
  - Quantity

I'm thinking that our ID should just be a generated ID.

Once a user creates a listing, we won't allow the name to change.
Users can have multiple listings with the same name, in fact,
everything could be the same, we don't really care.

Obviously, if a user has set a specific printing, then that field holds the ID
for that printing and we can use that to query the API for things like price.
If the user has not set a specific printing, we can set the printing column
to just be whatever the first ID that scryfall gives us, but price becomes
more difficult to handle. We could use the price for the default version,
but imo that's not the most helpful. Alternatively, we could do a little query
for all versions that exist for that card and then select the lowest to display.
When displaying a price like this, the caption should be in itallics to
have some implication that it's an estimate. We could even get the range
and display that.

The one concern I have for pricing integrated however, is the increased API usage.
We obviously want to minimize how much we hit the live API and, in the case of prices,
there's not really a way to avoid it? Maybe TCGPlayer has an API for pricing? Scryfall
does provide the TCGP id... TCGP does have an API, but its been deprecated.
So it turns out that scryfall does have a bulk API that can handle up to 75 cards
at a time. We could use this to load card data in when we fetch the wishlist, basically:

  getWishlist():
    const wishlist = fetch('wishlist')
    const prices = fetch('scryfall', wishlist.map(card => card.id))
    prices.forEach(price => wishlist[price.cardId].price = price.amount)
    return wishlist

this would only handle up to 75 cards at a time and for that there are a couple options:
  1. limit the size of a wishlist to 75 cards
    a. this would absolutely require us to support multiple wishlists per user
    b. this is also super annoying, especially if someone is setting up an inventory
  2. run multiple price queries in the getWishlist function
    a. good news is that this is doable and shouldn't hit a rate limit since it's only on page load
    b. bad news is that we still have to hit the api when doing an edit

Overall I just think that #2 is the better option. It could become a problem if someone has a massive
list that needs price data, but even a list of 1000 cards only requires 14 requests to be sent.
This is slightly above the limit of 10 requests/second though, so it could become an issue. Even so,
10 requests gets us 750 cards, which is more than the number of cards that exist in a set.

 */