import {
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { CardEntry, CardObject } from '../../models/card';
import CardDialog from '../CardDialog';
import CardGallery from '../CardGallery';
import ActionBar from '../ActionBar';
import CardList from '../CardList';
import { CardDialogFields } from '../CardDialog';

interface Props {
  cards: CardObject[];
  title: string;
  fullscreen?: boolean;
  addCard?: (card: CardEntry) => Promise<void>;
  removeCards: (ids: string[]) => Promise<void>;
}

export default function CardView({ cards, addCard, removeCards }: Props) {
  const theme = useTheme();
  const dialogFullscreen = useMediaQuery(theme.breakpoints.down('md'));

  const [highlightedCards, setHighlightedCards] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [searchParams] = useSearchParams();
  
  const [selectedCard, setSelectedCard] = useState<CardObject | null>(null);
  const [dialogState, setDialogState] = useState<Partial<CardDialogFields> | null>(null);

  const viewGallery = searchParams.get('view') === 'gallery';

  const filterTerms = (filter?.split(' ') ?? []).filter(f => !!f).map(f => f.toLowerCase());
  const cardList = cards
    .filter(a => {
      if (filterTerms.length === 0) {
        return true;
      }
      for(let f = 0; f < filterTerms.length; f++) {
        if (a.name.toLowerCase().includes(filterTerms[f])) {
          return true;
        }
      }
      return false;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleAdd = () => {
    setDialogState({});
  };

  const handleRemove = (ids: string[] = []) => {
    if (ids.length === 0) {
      if (selectedCard) {
        ids = [selectedCard.id];
      } else {
        return;
      }
    }

    setSelectedCard(null);
    setDialogState(null);

    removeCards?.(ids);
  };

  const handleSave = (fields: CardDialogFields) => {
    setSelectedCard(null);
    setDialogState(null);
    if (selectedCard) {
      selectedCard.update({
        id: selectedCard.id,
        ...fields,
      });
    } else {
      addCard?.({
        ...fields,
        id: '',
      });
    }
  };

  const handleSelect = (card: CardObject) => {
    setSelectedCard(card);
    setDialogState({
      name: card.name,
      quantity: card.quantity,
      foil: card.foil,
      printing: card.printing,
    });
  };

  return (
    <Box display="flex" flexDirection="row" minHeight="100%">
      <Box flex={1}>
        <ActionBar
          count={(highlightedCards ?? []).length}
          filter={filter}
          onAdd={handleAdd}
          onClear={() => setHighlightedCards([])}
          onFilter={setFilter}
          onRemove={() => handleRemove(highlightedCards)}
          onSelect={() => setHighlightedCards(cards.map(c => c.id))}
        />
        {viewGallery ? (
          <CardGallery
            highlightedCards={highlightedCards}
            cards={cardList}
            onAddCard={handleAdd}
            onHighlightCards={setHighlightedCards}
            //onRemoveCards={() => {
              //removeCards?.(highlightedCards.map(c => c.id)).then(setCards);
            //}}
            onSelectCard={handleSelect}
          />
        ) : (
          <CardList
            cards={cardList}
            highlightedCards={highlightedCards}
            onAddCard={handleAdd}
            onHighlightCards={setHighlightedCards}
            //onRemoveCards={() => {
              //removeCards?.(highlightedCards.map(c => c.id)).then(setCards);
            //}}
            onSelectCard={handleSelect}
          />
        )}
      </Box>
      <Box borderLeft="1px solid lightgrey" />
      <Box width={!!dialogState ? 400 : 0}>
        <CardDialog
          fullscreen={dialogFullscreen}
          open={!!dialogState}
          {...dialogState}
          onRemove={handleRemove}
          onSave={handleSave}
          onClose={() => setDialogState(null)}
        />
      </Box>
    </Box>
  )
}