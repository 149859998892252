import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import './index.css';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';
import Profile from './routes/profile';
import Wishlist from './routes/profile/wishlist';
import Login from './routes/Login';
import Reset from './routes/Reset';

const firebaseConfig = {
  apiKey: 'AIzaSyCgu4UCgu9ETtkjQqrhbzGEr1hvEUd6FyU',
  appId: '1:679890344634:web:7f21807d0782e358f94083',
  authDomain: 'can-we-trade.firebaseapp.com',
  databaseURL: 'https://can-we-trade-default-rtdb.firebaseio.com',
  measurementId: 'G-PQK1F33N9M',
  messagingSenderId: '679890344634',
  projectId: 'can-we-trade',
  storageBucket: 'can-we-trade.appspot.com',
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseDatabase = getDatabase(firebaseApp);

const queryClient = new QueryClient();

const router = createBrowserRouter([{
  path: '/',
  element: <App />,
  children: [{
    path: 'profile',
    element: <Profile />,
  }, {
    path: 'profile/wishlist',
    element: <Wishlist />,
  }]
}, {
  path: '/login',
  element: <Login />,
}, {
  path: '/reset',
  element: <Reset />,
}]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
