import {
  useEffect,
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import useSize from '../../hooks/useSize';
import { CardObject } from '../../models/card';
import CardListRow from './CardListRow';
import Header from './Header';

interface Props {
  cards: CardObject[];
  highlightedCards?: string[];
  onAddCard?: () => void;
  onHighlightCards?: (cards: string[]) => void;
  onRemoveCards?: () => void;
  onSelectCard?: (card: CardObject) => void;
}

export default function CardList({
  cards,
  highlightedCards,
  onHighlightCards,
  onSelectCard
}: Props) {

  // Make sure the highlighted cards are valid given the cards list.
  useEffect(() => {
    if (highlightedCards) {
      const highlights: string[] = [];
      highlightedCards?.forEach(hc => {
        if (cards.find(c => c.id === hc)) {
          highlights.push(hc);
        }
      });
      if (highlights.length !== highlightedCards.length && onHighlightCards) {
        onHighlightCards(highlights);
      }
    }
  }, [highlightedCards, onHighlightCards, cards]);
  
  return (
    <Table>
      {useSize('md') &&
        <Header
          highlight={(highlightedCards ?? []).length > 0}
          onHighlight={() => onHighlightCards?.((highlightedCards ?? []).length > 0 ? [] : cards.map(c => c.id))}
        />
      }
      <TableBody>
        {cards.map(card => (
          <CardListRow
            key={card.id}
            card={card}
            mode={(highlightedCards ?? []).length > 0 ? 'select' : 'default'}
            selected={highlightedCards?.length ? !!highlightedCards.find(hc => hc === card.id) : undefined}
            onPress={() => {
              if (highlightedCards?.length) {
                const index = highlightedCards.findIndex(hc => hc === card.id);
                if (index === -1) {
                  onHighlightCards?.([...highlightedCards, card.id]);
                } else {
                  onHighlightCards?.([...highlightedCards.slice(0, index), ...highlightedCards.slice(index + 1)]);
                }
              } else {
                onSelectCard?.(card);
              }
            }}
            onLongPress={() => {
              if (highlightedCards) {
                const index = highlightedCards.findIndex(hc => hc === card.id);
                if (index === -1) {
                  onHighlightCards?.([...highlightedCards, card.id]);
                } else {
                  onHighlightCards?.([...highlightedCards.slice(0, index), ...highlightedCards.slice(index + 1)]);
                }
              }
            }}
          />
        ))}
      </TableBody>
    </Table>
  );
}