import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import scryfall, {
  Card,
} from '../../models/scryfall';

interface Props {
  /** Query that is used to lookup possible card matches. */
  query: string;
  /** Name of the currently selected card. */
  selected: string;
  /** If the selector should be disabled. */
  disabled?: boolean;
  /** Called when the selected card changes. */
  onChange?: (card: Card) => void;
}

export default function CardSelector({
  query,
  selected,
  disabled,
  onChange,
}: Props) {
  const [cards, setCards] = useState<{ [key: string]: Card }>({});
  
  const options = useMemo(() => {
    return Object.values(cards)
        .map((card: Card): [string, string] => [card.id, card.name])
        .sort((a, b) => a[1].localeCompare(b[1]));
  }, [cards]);

  const selectedId = useMemo(() => {
    return Object.values(cards).find(c => c.name.toLowerCase() === selected.toLowerCase())?.id || '';
  }, [cards, selected]);

  // Handle the provided name changing.
  useEffect(() => {
    // Search card database.
    scryfall.getCardsWithName(query).then(results => {
      const newCards: any = {};
      results.forEach(c => newCards[c.id] = c)
      setCards(newCards);
    });    
  }, [query]);

  // Handle select input.
  const handleSelect = (event: SelectChangeEvent<string>) => {
    onChange?.(cards[event.target.value]);
  };

  return (
    <FormControl fullWidth>
      {options.length > 0 ? (
        <>
          <InputLabel id="name-select-label">Card</InputLabel>
          <Select labelId="name-select-label" label="Card" disabled={disabled} value={selectedId} onChange={handleSelect}>
            {options.map(([id, name]) =>
              <MenuItem key={id} value={id}>{name}</MenuItem>
            )}
          </Select>
        </>
      ) : (
        <DialogContentText>
          No cards were found for "{selected}" - please try another search.
        </DialogContentText>
      )}
    </FormControl>
  );
}