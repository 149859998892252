import {
  useState,
  FormEvent,
} from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';

type ErrorCode = 'auth/invalid-email' | 'auth/user-not-found' | 'auth/wrong-password';

const ERROR_MESSAGES: {
  [Code in ErrorCode]: string;
} = {
  'auth/invalid-email': 'The email format you provided is not supported.',
  'auth/user-not-found': 'The email or password you entered is incorrect.',
  'auth/wrong-password': 'The email or password you entered is incorrect.',
};

export default function Login() {
  const [error, setError] = useState<ErrorCode | null>(null);
  let errorMessage = '';
  if (error) {
    errorMessage = Object.hasOwn(ERROR_MESSAGES, error) ? ERROR_MESSAGES[error] : `An unexpected error has occurred. (ErrorCode ${error})`;
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.toString();
    const password = data.get('password')?.toString();
    const remember = !!data.get('remember');
    if (!email || !password) {
      return;
    }

    const auth = getAuth();
    setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence)
      .then(() => signInWithEmailAndPassword(auth, email, password))
      .then(() => window.location.pathname = '')
      .catch(({ code }: { code: ErrorCode }) => {
        setError(code);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login to Can We Trade
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={!!error}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!error}
          />
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          <FormControlLabel
            control={<Checkbox id="remember" name="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="div" underline="hover" variant="body2">
                <RouterLink to="/reset">
                  Forgot password?
                </RouterLink>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}