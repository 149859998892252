import {
  ChangeEvent,
} from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  value: number;
  onChange: (value: number) => void;
}

export default function QuantitySelector({
  value,
  onChange,
}: Props) {

  const handleSlider = (_: unknown, val: number | number[]) => {
    if (Array.isArray(val)) {
      onChange(val[0]);
    } else {
      onChange(val);
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      onChange(1);
      return;
    }

    try {
      let val = Number.parseInt(event.target.value);
      if (val > 9999) {
        val = 9999;
      }
      onChange(val);
    } catch {
      onChange(1);
    }
  }

  return (
    <Box>
      <Typography gutterBottom>Quantity</Typography>
      <Box
        display="flex"
        alignItems="center"
        marginLeft={2}
      >
        <Slider
          step={1}
          min={1}
          max={10}
          marks={true}
          value={value}
          onChange={handleSlider}
        />
        <TextField
          size="small"
          type="number"
          value={value}
          onChange={handleInput}
          sx={{
            marginLeft: 4,
            width: 100,
          }}
        />
      </Box>
    </Box>
  )
}