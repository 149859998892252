import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ActionBarProps,
} from './index.common';
import Desktop from './index.desktop';
import Mobile from './index.mobile';

export default function ActionBar(props: ActionBarProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />;
}