import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ViewSelect from './ViewSelect';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import SearchField from '../SearchField';
import {
  ActionBarProps,
} from './index.common';

export default function DesktopActionBar({
  count,
  filter,
  onAdd,
  onClear,
  onFilter,
  onRemove,
}: ActionBarProps) {
  return (
    <AppBar
      color="inherit"
      position="sticky"
      sx={{
        top: 0,
        bottom: 'auto',
      }}
    >
      <Toolbar>
        <SearchField
          gutterBottom
          size="small"
          sx={{ flex: 1 }}
          onChange={onFilter}
        />
        <ViewSelect />
        <Button
          size="large"
          startIcon={<AddIcon />} 
          onClick={onAdd}
          sx={{
            marginLeft: 1,
          }}
        >
          Add
        </Button>
        <Button
          size="large"
          disabled={count === 0}
          startIcon={<DeleteIcon />} 
          onClick={onRemove}
          sx={{
            marginLeft: 1,
          }}
        >
          Remove
        </Button>
      </Toolbar>
    </AppBar>
  )
}