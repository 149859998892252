import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import {
  DRAWER_WIDTH,
  DrawerMenu,
  SideDrawerProps,
} from './index.common';

export default function MobileSideDrawer({ open, onClose }: SideDrawerProps) {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <Box
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        sx={{
          width: DRAWER_WIDTH,
        }}
      >
        <Toolbar />
        <DrawerMenu />
      </Box>
    </Drawer>
  );
}
