import { useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  Foiling,
} from '../../models/card';
import {
  Card,
} from '../../models/scryfall';

interface Props {
  /** Valid options to show for foiling. */
  finishes: Card['finishes'];
  /** Currently selected foil treatment. */
  foil: '' | 'nonfoil' | 'foil' | 'etched' | null;
  /** Called when the selected foil treatment changes. */
  onChange?: (foil: Foiling | null) => void;
}

export default function FoilSelector({
  finishes,
  foil,
  onChange,
}: Props) {
  const options: {
    label: string,
    value: string,
  }[] = useMemo(() => {
    const newOptions: {
      label: string,
      value: string,
    }[] = [];
    if (finishes.length > 1) {
      newOptions.push({
        label: 'Any',
        value: '',
      });
    }
    if (finishes.includes('nonfoil')) {
      newOptions.push({
        label: 'Non-Foil',
        value: 'nonfoil',
      });
    }
    if (finishes.includes('foil')) {
      newOptions.push({
        label: 'Foil',
        value: 'foil',
      });
    }
    if (finishes.includes('etched')) {
      newOptions.push({
        label: 'Etched',
        value: 'etched',
      });
    }
    return newOptions;
  }, [finishes]);

  // Handle select input.
  const handleSelect = (_: unknown, value: (Foiling | '')) => {
    onChange?.(value === '' ? null : value);
  };

  return (
    <FormControl fullWidth>
      <ToggleButtonGroup exclusive value={foil ?? ''} onChange={handleSelect}>
        {options.map(({ label, value }) => <ToggleButton key={value} value={value} sx={{flex: 1}}>{label}</ToggleButton>)}
      </ToggleButtonGroup>
    </FormControl>
  );
}