import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Props {
  highlight: boolean;
  onHighlight: () => void;
}

export default function Header({ highlight, onHighlight }: Props) {
  return (
    <TableHead sx={{ top: 64 }}>
      <TableRow>
        <TableCell padding="checkbox"><Checkbox checked={highlight} onChange={onHighlight} /></TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell>Price</TableCell>
        <TableCell width={8} sx={{ padding: 0 }}></TableCell>
      </TableRow>
    </TableHead>
  );
}