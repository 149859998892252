import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface Props {
  name: string;
  left: {
    label: string;
    element: ReactElement;
  };
  right: {
    label: string;
    element: ReactElement;
  };
  size?: 'medium' | 'small';
}

/** Provides a toggle button that updates the route search parmas. */
export default function SearchToggleButton({
  name,
  left,
  right,
  size,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(name);
  
  const theme = useTheme();

  return (
    <MuiToggleButtonGroup
      exclusive
      value={value || left.label}
      onChange={(_, v) => {
        const oldValue = value || left.label;
        const newValue = oldValue === left.label ? right.label : left.label;
        searchParams.set(name, newValue);
        setSearchParams(searchParams, { replace: true });
      }}
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <MuiToggleButton size={size} value={left.label}>{left.element}</MuiToggleButton>
      <MuiToggleButton size={size} value={right.label}>{right.element}</MuiToggleButton>
    </MuiToggleButtonGroup>
  );
}