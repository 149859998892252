import {
  Fragment,
  useEffect,
} from 'react';
import Grid from '@mui/material/Grid';
import { CardObject } from '../../models/card';
import GalleryItem from './GalleryItem';

interface Props {
  cards: CardObject[];
  highlightedCards?: string[];
  onAddCard?: () => void;
  onHighlightCards?: (cards: string[]) => void;
  onRemoveCards?: () => void;
  onSelectCard?: (card: CardObject) => void;
}

export default function CardGallery({
  cards,
  highlightedCards,
  onHighlightCards,
  onSelectCard,
}: Props) {
  // Make sure the highlighted cards are valid given the cards list.
  useEffect(() => {
    if (highlightedCards) {
      const highlights: string[] = [];
      highlightedCards?.forEach(hc => {
        if (cards.find(c => c.id === hc)) {
          highlights.push(hc);
        }
      });
      if (highlights.length !== highlightedCards.length && onHighlightCards) {
        onHighlightCards(highlights);
      }
    }
  }, [highlightedCards, onHighlightCards, cards]);

  return (
    <>
      <Grid container padding={1} spacing={1}>
        {(cards ?? []).map(card => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={card.id}>
            <GalleryItem
              card={card}
              mode={(highlightedCards || []).length > 0 ? 'select' : 'default'}
              selected={highlightedCards?.includes(card.id)}
              onPress={() => {
                if (highlightedCards?.length) {
                  const index = highlightedCards.findIndex(hc => hc === card.id);
                  if (index === -1) {
                    onHighlightCards?.([...highlightedCards, card.id]);
                  } else {
                    onHighlightCards?.([...highlightedCards.slice(0, index), ...highlightedCards.slice(index + 1)]);
                  }
                } else {
                  onSelectCard?.(card);
                }
              }}
              onLongPress={() => {
                if (highlightedCards) {
                  const index = highlightedCards.findIndex(hc => hc === card.id);
                  if (index === -1) {
                    onHighlightCards?.([...highlightedCards, card.id]);
                  } else {
                    onHighlightCards?.([...highlightedCards.slice(0, index), ...highlightedCards.slice(index + 1)]);
                  }
                }
              }}
            /> 
          </Grid>
        ))}
      </Grid>
    </>
  )
}