import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardView from '../../components/CardView';
import WishlistComponent from '../../components/Wishlist';

export default function ProfileRoute () {
  return (
    <Container  sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <WishlistComponent />
        </Grid>
      </Grid>
    </Container>
  );
}