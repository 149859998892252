import {
  useState,
  MouseEvent,
} from 'react';
import {
  getAuth,
  signOut,
} from 'firebase/auth';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AccountDefaultIcon from '@mui/icons-material/AccountCircle';
import AccountMissingIcon from '@mui/icons-material/NoAccounts';
import useUser from '../../hooks/useUser';

export default function AccountFlyout() {
  const { user } = useUser();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const open = !!anchorElement;

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(null);
    switch (event.currentTarget.id) {
      case 'account-flyout-menuitem-account':
        break;
      case 'account-flyout-menuitem-login':

        break;
      case 'account-flyout-menuitem-logout':
        break;
    }
  };
  
  const handleLogin = () => {
    window.location.pathname = 'login';
  }

  const handleLogout = () => {  
    signOut(getAuth()).then(() => window.location.reload());
  };

  return (
    <>
      <Button
        id="account-flyout-button"
        color="inherit"
        size="large"
        aria-controls={open ? 'account-flyout-menu' : undefined}
        aria-expanded={open ? true : undefined}
        aria-haspopup={true}
        endIcon={user ? <AccountDefaultIcon /> : <AccountMissingIcon />}
        onClick={handleOpen}
      >
        <Typography textTransform="none">{user ? user.email : 'Demo Mode'}</Typography>
      </Button>
      <Menu
        id="account-flyout-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-flyout-button',
        }}
      >
        <MenuItem onClick={handleClose}>My Account</MenuItem>
        {!!user || <MenuItem onClick={handleLogin}>Login</MenuItem>}
        {!!user && <MenuItem onClick={handleLogout}>Logout</MenuItem>}
      </Menu>
    </>
  );
}