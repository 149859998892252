import { Card as ScryfallCard } from 'scryfall-api';

/** Searches scryfall for cards with a similar name. */
export const searchCards = async (name: string): Promise<any[]> => {
  const response = await (await fetch(`https://api.scryfall.com/cards/search?q=${encodeURIComponent(`name:${name} game:paper`)}`)).json();
  return response.data ?? [];
}

/** Searches scryfall for all printings of the card with an exact name. */
export const searchCardPrints = async (name: string): Promise<any[]> => {
  const response = await (await fetch(`https://api.scryfall.com/cards/search?unique=prints&q=${encodeURIComponent(`name:"${name}" game:paper`)}`)).json();
  return response.data ?? [];
}

const SCRYFALL_URL = 'https://api.scryfall.com';

export interface Card extends ScryfallCard {
  finishes: ('nonfoil' | 'foil' | 'etched')[];
};

export class Scryfall {
  private _autocomplete: {
    promise?: {
      result?: Promise<string[]>;
      resolve?: (value: string[]) => void;
      reject?: (reason: any) => void;
    };
    search: string;
    waiting: boolean;
  } = {
    search: '',
    waiting: false,
  };

  private _cache: {
    byId: {
      [id: string]: Promise<Card>;
    };
    byName: {
      [name: string]: Promise<Card>;
    };
  } = {
    byId: {},
    byName: {},
  };

  private _queue: ({
    key: 'id' | 'name',
    search: string,
    promise: {
      resolve: (value: Card) => void,
      reject: (reason: any) => void,
    },
  })[] = [];

  private _workInterval: number = 0;

  public getAutocomplete(name: string): Promise<string[]> {
    // First time setup of the promise object.
    if (!this._autocomplete.promise) {
      this._autocomplete.promise = {};

      this._autocomplete.promise.result = new Promise((resolve, reject) => {
        if (this._autocomplete.promise) {
          this._autocomplete.promise.resolve = resolve;
          this._autocomplete.promise.reject = reject;
        }
      });  
    }

    // Configure search and waiting for new requests.
    if (this._autocomplete.search !== name) {
      this._autocomplete.search = name;
      this._autocomplete.waiting = true;
    }

    // Return the autocomplete promise;
    return this._autocomplete.promise.result!;
  }

  public getCardById(id: string): Promise<Card> {
    if (!this._cache.byId[id]) {
      this._cache.byId[id] = new Promise<Card>((resolve, reject) => {
        const q = {
          key: 'id' as const,
          search: id,
          promise: {
            resolve,
            reject,
          },
        };
        this._queue.push(q);
      });
    }
    return this._cache.byId[id];
  }

  public getCardByName(name: string): Promise<Card> {
    if (!this._cache.byName[name]) {
      this._cache.byName[name] = new Promise<Card>((resolve, reject) => {
        const q = {
          key: 'name' as const,
          search: name,
          promise: {
            resolve,
            reject,
          },
        };
        this._queue.push(q);
      });
    }
    return this._cache.byName[name];
  }

  public getCardsWithName(name: string): Promise<Card[]> {
    const query = encodeURIComponent(name);
    return fetch(`${SCRYFALL_URL}/cards/search?q=${query}`)
      .then(response => response.json())
      .then(json => json.data);
  }

  public getPrintsByName(name: string): Promise<Card[]> {
    return this._getAllCardsWithNames([name], true)
      .then(cards => cards.filter(card => card.name === name));
  }

  public startWork() {
    if (!this._workInterval) {
      this._workInterval = window.setInterval(() => {
        this._workAutocomplete();
        this._workQueue();
      }, 1000);
    }
  }

  public stopWork() {
    if (this._workInterval) {
      window.clearInterval(this._workInterval);
      this._workInterval = 0;
    }
  }

  private _getAutocomplete(name: string): Promise<string[]> {
    const query = encodeURIComponent(name);
    return fetch(`${SCRYFALL_URL}/cards/autocomplete?q=${query}`)
      .then(response => response.json())
      .then(json => json.data);
  }

  private async _getAllCardsWithIds(ids: string[]): Promise<Card[]> {
    const cards = [];
    let splice;
    while ((splice = ids.splice(0, 75)).length > 0) {
      const request = {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identifiers: splice.map(s => ({
            id: s,
          })),
        }),
      };
      const response = await (await fetch(`${SCRYFALL_URL}/cards/collection`, request)).json();
      cards.push(...response.data);
    }
    return cards;
  }

  private async _getAllCardsWithNames(names: string[], prints?: boolean, limit?: number): Promise<Card[]> {
    const cards = [];
    let page = 1;
    let response: any;
    do {
      const query = encodeURIComponent(`(${names.map(n => `!"${n}"`).join(' or ')}) game:paper`);
      response = await (await fetch(`${SCRYFALL_URL}/cards/search?page=${page}&${prints ? 'unique=prints&' : ''}q=${query}`)).json();
      cards.push(...response.data);
      page++;
    } while (response && response.has_more && (limit && limit >= page));
    return cards;
  }

  /** Uses 1 of our 10 calls per second. */
  private _workAutocomplete() {
    if (this._autocomplete.waiting && this._autocomplete.promise && this._autocomplete.promise.resolve) {
      this
        ._getAutocomplete(this._autocomplete.search)
        .then(autocomplete => {
          this._autocomplete.promise!.resolve!(autocomplete);
        });

      this._autocomplete.waiting = false;
    }
  }

  /** Uses 3 of our 10 calls per second. */
  private _workQueue() {
    const work = this._queue.splice(0, 50);
    if (work.length > 0) {
      const idLookups = work.filter(w => w.key === 'id');
      if (idLookups.length > 0) {
        this
          ._getAllCardsWithIds(idLookups.map(l => l.search))
          .then(cards => {
            cards.forEach(card => {
              idLookups.find(w => w.search === card.id)?.promise.resolve(card as any);
            });
          });
      }

      const nameLookups = work.filter(w => w.key === 'name');
      if (nameLookups.length > 0) {
        this
          ._getAllCardsWithNames(nameLookups.map(l => l.search), false)
          .then(cards => {
            cards.forEach(card => {
              nameLookups.find(w => w.search === card.name)?.promise.resolve(card as any);
            });
          });
      }
    }
  }
}

const scryfall = new Scryfall();
scryfall.startWork();
export default scryfall;