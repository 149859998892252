import CardView from './CardView';
import useWishlist from '../models/wlist';
import wishlist from '../models/wishlist';

interface WishlistComponentProps {
  fullscreen?: boolean;
}

export default function WishlistComponent ({ fullscreen }: WishlistComponentProps) {
  const w = useWishlist();
  return <CardView
    cards={w.entries}
    fullscreen={fullscreen}
    title="Wishlist"
    addCard={card => {
      w.add(card);
      return Promise.resolve();
    }}
    removeCards={ids => {
      w.remove(ids);
      return Promise.resolve();
    }}
  />
}