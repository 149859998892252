import {
  forwardRef,
  useEffect,
  useState,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import {
  TransitionProps,
} from '@mui/material/transitions';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import {
  CardEntry,
  Foiling,
} from '../../models/card';
import scryfall, {
  Card,
} from '../../models/scryfall';
import SearchField from '../SearchField';
import CardSelector from './CardSelector';
import FoilSelector from './FoilSelector';
import Preview from './Preview';
import PrintSelector from './PrintSelector';
import QuantitySelector from './QuantitySelector';

export type CardDialogFields = Omit<CardEntry, 'id'>;
export type CardDialogEvents = {
  onClose?: () => void;
  onRemove?: () => void;
  onSave?: (fields: CardDialogFields) => void;
}
export type CardDialogProps = Partial<CardDialogFields> & CardDialogEvents & {
  fullscreen: boolean;
  open: boolean;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
});

const Wrapper : (props: {
  fullscreen: boolean,
  open: boolean,
  onClose: () => void,
  children: ReactNode,
}) => ReactElement = ({
  fullscreen,
  open,
  onClose,
  children,
}) => {
  return fullscreen ? (
    <Dialog
      fullScreen={true}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      {children}
    </Dialog>
  ) : (
    open ? (
      <Box position="sticky" top="0">
        {children}
      </Box>
    ) : (
      <></>
    )
  );
};

export default function CardDialog({
    // Dialog state
    fullscreen,
    open,
    // Card fields
    name,
    quantity,
    foil,
    printing,
    // Dialog events
    onClose,
    onRemove,
    onSave,
}: CardDialogProps) {
  // Current Scyrfall card object that's been selected for this card.
  const [card, setCard] = useState<Card | null>(null);

  // Card to use if no printing has been selected.
  const [defaultCard, setDefaultCard] = useState<Card | null>(null);

  // Fields used by the dialog form.
  const [searchQuery, setSearchQuery] = useState<string>('');
  
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const [selectedFoil, setSelectedFoil] = useState<Foiling | null>(null);
  const [selectedPrint, setSelectedPrint] = useState<string | null>(null);

  const [currentFinishes, setCurrentFinishes] = useState<('nonfoil' | 'foil' | 'etched')[] | null>(null);
  const [defaultFinishes, setDefaultFinishes] = useState<('nonfoil' | 'foil' | 'etched')[]>([]);

  useEffect(() => {
    setCard(null);
    setDefaultCard(null);
    setSearchQuery('');
    setSelectedName(name ?? null);
    setSelectedQuantity(quantity ?? 1);
    setSelectedFoil(foil ?? null);
    setSelectedPrint(printing ?? null);

    if (printing) {
      scryfall.getCardById(printing).then((card) => {
        setCard(card);
      });
    } else if (name) {
      scryfall.getCardByName(name).then((card) => {
        setCard(card);
      });
    }
  }, [name, quantity, foil, printing]);
  
  const reset = () => {
    setCard(null);
    setSearchQuery('');
    setSelectedName(null);
    setSelectedQuantity(1);
    setSelectedFoil(null);
    setSelectedPrint(null);
  };

  const handleClose = () => {
    reset();
    onClose?.();
  };

  const handleRemove = () => {
    reset();
    onRemove?.();
  };

  const handleSave = () => {
    if (selectedName) {
      onSave?.({
        name: selectedName,
        quantity: selectedQuantity,
        foil: selectedFoil ?? undefined,
        printing: selectedPrint ?? undefined,
      });
    } else {
      console.error('Cannot save card when nothing is selected.');
    }
  }

  const handleSearch = (s: string) => {
    setSearchQuery(s);
    setCard(null);
    setDefaultCard(null);
  };

  const handleCardSelect = (c: Card) => {
    setCard(c);
    setDefaultCard(c);
    setSelectedName(c?.name);
    setSelectedPrint(null);
  }

  const handleQuantitySelect = (q: number) => {
    setSelectedQuantity(q);
  }

  const handlePrintLoad = (c: Card[]) => {
    let f: ('nonfoil' | 'foil' | 'etched')[] = [];
    c.forEach(card => {
      if (card.finishes.includes('nonfoil')) {
        f.push('nonfoil');
      }
      if (card.finishes.includes('foil')) {
        f.push('foil');
      }
      if (card.finishes.includes('etched')) {
        f.push('etched');
      }
    });
    setDefaultFinishes(f);
  }

  const handlePrintSelect = (c: Card | null) => {
    if (c) {
      setCard(c);
      setSelectedPrint(c.id);
      setCurrentFinishes(c.finishes);
    } else {
      setCard(defaultCard);
      setSelectedPrint(null);
      setCurrentFinishes(null);
    }
  };

  const handleFoilSelect = (f: Foiling | null) => {
    setSelectedFoil(f);
  }

  return (
    <Wrapper
      fullscreen={fullscreen}
      open={open}
      onClose={handleClose}
    >
      {/** Header */}
      <AppBar color={fullscreen ? undefined : "inherit"} sx={{ position: 'relative' }}>
        <Toolbar>
          <>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {name ? name : 'Add Card'}
            </Typography>
            <IconButton autoFocus color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </>
        </Toolbar>
      </AppBar>
      {/** Background card art */}
      {fullscreen && card && card.image_uris &&
        <img
          alt="Background"
          src={card.image_uris.art_crop}
          style={{
            top: 0,
            left: 0,
            height: '100%',
            objectFit: 'cover',
            position: 'fixed',
            width: '100%',
          }}
        />
      }
      {/** Dialog content section w/ transparent background to show card art */}
      <DialogContent sx={{zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.9)'}}>
        {/** Search field */}
        {!name && <SearchField onSearch={handleSearch} />}
        <Grid container spacing={3}>
          {/** Card selector */}
          <Grid item xs={12}>
            {(searchQuery || name) && <CardSelector disabled={!!name} query={name || searchQuery} selected={name || selectedName || ''} onChange={handleCardSelect} />}
          </Grid>
          {card && <>
            {/** Quantity selector */}
            <Grid item xs={12}>
              <QuantitySelector value={selectedQuantity} onChange={handleQuantitySelect} />
            </Grid>
            {/** Print selector */}
            <Grid item xs={12}>
              <PrintSelector name={card.name} printing={selectedPrint} onChange={handlePrintSelect} onLoad={handlePrintLoad} />
            </Grid>
            {/** Foil selector */}
            <Grid item xs={12}>
              <FoilSelector finishes={currentFinishes ?? defaultFinishes} foil={selectedFoil} onChange={handleFoilSelect} />
            </Grid>
            {/** Preview image */}
            <Grid item xs={12}>
              {card.image_uris && <Preview foil={(selectedFoil && selectedFoil !== 'nonfoil') || !card.finishes.includes('nonfoil')} url={card.image_uris.border_crop} />}
            </Grid>
          </>}
          {/** Footer */}
          {card &&
            <Grid
              item
              display="flex"
              justifyContent="right"
              xs={12}
            >
              {name && <Button variant="outlined" onClick={handleRemove} sx={{ marginRight: 3, width: 100 }}>Remove</Button>}
              <Button variant="contained" disabled={!card} onClick={handleSave} sx={{ width: 100 }}>{name ? 'Save' : 'Add'}</Button>
            </Grid>
          }
        </Grid>
      </DialogContent>
    </Wrapper>
  );
}