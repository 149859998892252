import {
  useMemo,
  useState,
} from 'react';
import {
  getAuth,
  onAuthStateChanged,
  User,
} from 'firebase/auth';

export default function useUser() {
  const [user, setUser] = useState<{
    isLoading: boolean,
    user: User | null,
  }>({
    isLoading: true,
    user: null,
  });

  useMemo(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      setUser({ isLoading: false, user });
    });
  }, []);

  return user;
}