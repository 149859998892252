import {
  useEffect,
  useRef,
  useState,
  MouseEvent,
  TouchEvent,
} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useSize from '../../hooks/useSize';
import {
  FOIL_COLOR,
  CardObject,
} from '../../models/card';

const THRESHOLD_DEFAULT = 400;

type Mode = 'default' | 'select';

interface Props {
  card: CardObject;
  mode: Mode;
  threshold?: number;
  selected?: boolean;
  onPress?: () => void;
  onLongPress?: () => void;
}

export default function CardListRow({
  card,
  mode,
  threshold,
  selected,
  onPress,
  onLongPress,
}: Props) {
  // Display properties.
  const [foil, setFoil] = useState<boolean>(false);
  const [price, setPrice] = useState<string | undefined>();

  // Set the card properties gotten from scryfall.
  useEffect(() => {
    card.getScryfallCard().then(sc => {
      const foil = (card.foil && card.foil !== 'nonfoil') || !sc.finishes.includes('nonfoil');
      setFoil(foil);
      setPrice(foil ? sc.prices?.usd_foil : sc.prices?.usd);
    });
  }, [card, card.foil, card.name, card.printing]);

  // Set when the input is active and not resolved.
  const [isInputActive, setIsInputActive] = useState<boolean>(false);
  const isInputActiveRef = useRef(isInputActive);
  useEffect(() => {
    isInputActiveRef.current = isInputActive;
  }, [isInputActive]);

  // Records the scroll position when input last became active.
  // Used to determine if the user was simply scrolling via touch controls
  // in which case the input event is ignored.
  const [inputScrollY, setInputScrollY] = useState<number>(0);
  const inputScrollYRef = useRef(inputScrollY);
  useEffect(() => {
    inputScrollYRef.current = inputScrollY;
  }, [inputScrollY]);

  // Called when the user performs an action that marks the start of an input event.
  // Example events would be when the user presses their finger to the screen
  // or holds down their mouse button.
  const onInputActivated = (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>): void => {
    event.nativeEvent.preventDefault();
    
    // Record that an input event has started.
    setIsInputActive(true);
    setInputScrollY(window.scrollY);

    // Create a timeout that will be used to fire a "long press" event
    // should the user maintain their input for 400ms or longer.
    setTimeout(() => {
      // If the input has already been released or the screen was scrolled, we just throw away the timeout.
      if (isInputActiveRef.current && Math.abs(inputScrollYRef.current - window.scrollY) < 1) {
        // Fire the "long press" event handler.
        onLongPress?.();
      }
      // Reset the input state.
      setIsInputActive(false);
      setInputScrollY(0);
    }, threshold || THRESHOLD_DEFAULT);
  };

  // Called when the user performs an action that marks the end of an input event.
  // Example events are when a user removes their finger from the screen or releases the mouse button.
  const onInputReleased = (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>): void => {
    event.nativeEvent.preventDefault();

    // If the input has been held beyond the threshold or the screen was scrolled, we just throw away the event.
    if (isInputActiveRef.current && Math.abs(inputScrollYRef.current - window.scrollY) < 1) {
      // Fire the "press" event handler.
      onPress?.();
    }
    // Reset the input state.
    setIsInputActive(false);
    setInputScrollY(0);
  };

  const cellEvents = {
    onContextMenu: (event: MouseEvent<HTMLTableCellElement>) => {
      event.nativeEvent.preventDefault();
    },
    onMouseDown: (event: MouseEvent<HTMLTableCellElement>) => {
      // Primary click.
      if (event.buttons === 1) {
        onInputActivated(event);
      }
    },
    onMouseUp: onInputReleased,
    onTouchStart: onInputActivated,
    onTouchEnd: onInputReleased,
  }

  return (
    <TableRow
      hover={useSize('md')}
      selected={isInputActive}
      sx={{
        cursor: mode === 'select' ? 'cell' : 'pointer',
      }}
    >
      <>
        {(useSize('md') || selected !== undefined) &&
          <TableCell padding="checkbox">
            <Checkbox checked={!!selected} onChange={() => onLongPress?.()}/>
          </TableCell>
        }
      </>
      <TableCell
        sx={{
          userSelect: 'none',
          msUserSelect: 'none',
          MozUserSelect: 'none',
          WebkitUserSelect: 'none',
        }}
        {...cellEvents}
      >
        {card.name}
      </TableCell>
      <TableCell {...cellEvents}>
        x{card.quantity}
      </TableCell>
      <TableCell {...cellEvents}>
        ${price}
      </TableCell>
      <TableCell width={8} sx={{ background: foil ? FOIL_COLOR : undefined, padding: 0 }} {...cellEvents}>
        <div />
      </TableCell>
    </TableRow>
  );
}