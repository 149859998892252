import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {
  SelectChangeEvent
} from '@mui/material/Select';
import scryfall, {
  Card,
} from '../../models/scryfall';

interface Props {
  /** Name that will be used to lookup possible card matches. */
  name: string;
  /** Currently selected printing. */
  printing: string | null;
  /** Called when the selected card changes. */
  onChange?: (card: Card | null) => void;
  /** Called when the printings have reloaded. */
  onLoad?: (cards: Card[]) => void;
}

export default function PrintSelector({
  name,
  printing,
  onChange,
  onLoad,
}: Props) {
  const [cards, setCards] = useState<{ [key: string]: Card }>({});

  const options = useMemo(() => {
    const newOptions = [
      ['', 'Any'] as [string, string],
      ...Object.values(cards)
        .map((c: any): [string, string] => [c.id, `${c.set_name}${c.collector_number >= 0 ? ` #${c.collector_number}` : ''}`])
        .sort((a, b) => a[1].localeCompare(b[1]))
    ]
    
    if (newOptions.length === 2) {
      newOptions.shift();
    }

    return newOptions;
  }, [cards]);

  // Handle the provided name changing.
  useEffect(() => {
    // Search card database.
    scryfall.getPrintsByName(name).then(results => {
      const newCards: {
        [key: string]: Card;
      } = {};
      results.forEach(c => newCards[c.id] = c);
      setCards(newCards);
      onLoad?.(results);
    });
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle select input.
  const handleSelect = (event: SelectChangeEvent<string>) => {
    onChange?.(cards[event.target.value]);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="print-select-label">Printing</InputLabel>
      <Select labelId="print-select-label" label="Printing" value={printing || ''} onChange={handleSelect}>
        {options.map(([id, name]) =>
          <MenuItem key={id} value={id}>{name}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}