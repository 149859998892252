import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  child,
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  Unsubscribe,
  update,
} from 'firebase/database';
import useUser from '../hooks/useUser';
import {
  CardEntry,
  CardObject,
} from '../models/card';

interface Wishlist {
  add: (card: CardEntry) => void;
  remove: (ids: string[]) => void;
  entries: CardObject[];
}

export default function useWishlist(): Wishlist {
  const { user } = useUser();
  const unsubscribe = useRef<Unsubscribe>(() => {});
  const [entries, setEntries] = useState<CardObject[]>([]);

  // Setup callback to use for adding cards to the wishlist.
  const add = useCallback((card: CardEntry | CardObject) => {
    if (user) {
      const entry: CardEntry = ((card as Object).constructor.name === 'CardObject') ? (card as CardObject).toEntry() : card;
      const database = getDatabase();
      const databaseRef = ref(database);
      
      const uid = push(child(databaseRef, `wishlists/${user.uid}`)).key;
      if (uid) {
        const newCard: Omit<CardEntry, 'id'> = {
          name: entry.name,
          quantity: entry.quantity,
          foil: entry.foil ?? '',
          printing: entry.printing ?? '',
        };

        const updates: any = {};
        updates[`wishlists/${user.uid}/${uid}`] = newCard;

        update(databaseRef, updates);
      }
    }
  }, [user]);

  const rem = useCallback((ids: string[]) => {
    if (user && ids.length > 0) {
      ids.forEach(id => {
        const database = getDatabase();
        const cardRef = ref(database, `wishlists/${user.uid}/${id}`);
        remove(cardRef);
      });
    }
  }, [user]);

  // Setup observable for updating wishlist when changes are made server-side.
  useEffect(() => {
    unsubscribe.current();
    if (user) {
      const database = getDatabase();
      const wishlistRef = ref(database, `wishlists/${user.uid}`);
      const unsub = onValue(wishlistRef, snapshot => {
        const scval = snapshot.val();
        if (scval) {
          const list: CardObject[] = [];
          Object.keys(scval).forEach(key => {
            list.push(new CardObject({
              ...scval[key],
              id: key,
            }));
          });
          setEntries(list);
        } else {
          setEntries([]);
        }
      });
      unsubscribe.current = unsub;
    } else {
      setEntries([]);
      unsubscribe.current = () => {};
    }
  }, [user]);

  // Memo-ize the wishlist API interface struct.
  const wishlist = useMemo(() => ({
    add,
    remove: rem,
    entries
  }), [add, rem, entries]);

  return wishlist;
}