import Box from '@mui/material/Box';
import {
  ART_ASPECT_RATIO,
  BORDER_COLOR,
  FOIL_COLOR,
} from '../../models/card';

interface Props {
  foil: boolean;
  url: string;
}

export default function Preview({
  foil,
  url,
}: Props) {
  return (
    <Box
      bgcolor="lightgrey"
      border="1px solid grey"
      borderRadius={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={300}
      padding={1}
      width="100%"
    >
      <div
        style={{
          backgroundColor: BORDER_COLOR,
          padding: '4px 4px 0 4px',
          borderRadius: 8,
          position: 'relative',
          maxHeight: '100%',
          maxWidth: '100%',
          aspectRatio: ART_ASPECT_RATIO,
        }}
      >
        <img
          alt="Preview"
          src={url}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
        {foil &&
          <div
            style={{
              background: FOIL_COLOR,
              borderRadius: 8,
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              pointerEvents: 'none',
            }}
          />
        }
      </div>
    </Box>
  )
}