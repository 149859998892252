import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * Determines if a given size should be used for layout.
 * The size is considered usable if the current layout is wider
 * or equal to the given breakpoint.
 * @param size Breakpoint at which this size should be used.
 */
export default function useSize(size: Size) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(size));
}