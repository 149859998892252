import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SideDrawerProps } from './index.common';
import Desktop from './index.Desktop';
import Mobile from './index.Mobile';

export default function SideDrawer(props: SideDrawerProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />;
}