import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import {
  useLocation,
  Outlet,
} from 'react-router-dom';
import SideDrawer from './SideDrawer';
import AccountFlyout from './AccountFlyout';

const DRAWER_WIDTH: number = 250;

const Copyright = (props: any) => (
  <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {'Copyright © '}
    <Link color="inherit" href="https://mui.com/">
      canwe.trade
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: DRAWER_WIDTH,
  }),
}));

const mdTheme = createTheme();

export default function Dashboard() {
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const isMobile = useMediaQuery(mdTheme.breakpoints.down('md'));
  const location = useLocation().pathname.split('/').pop();
  const locationName = location ? location.slice(0, 1).toUpperCase() + location.slice(1) : 'Can We Trade';

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <AppBar
          position='static'
          sx={{
            zIndex: mdTheme.zIndex.drawer + 1
          }}
        >
          <Toolbar
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Button
              color="inherit"
              size="large"
              startIcon={open ? <ChevronLeftIcon /> : <MenuIcon />}
              onClick={toggleDrawer}
            >
              <Typography
                noWrap
                component="h1"
                textTransform="none"
                variant="h6"
              >
                {locationName}
              </Typography>
            </Button>
            <AccountFlyout />
          </Toolbar>
        </AppBar>
        <SideDrawer open={open} onClose={() => setOpen(false)} />
        <Main open={!isMobile && open} sx={{
          padding: 0,
          ...(isMobile ? {} : {
            height: 'calc(100vh - 64px)',
            overflowX: 'hidden',
          }),
        }}>
          <Outlet />
        </Main>
      </Box>
    </ThemeProvider>
  );
}
